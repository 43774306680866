//// Import bootstrap 5
// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// Any default variable overrides here
// *Global
$enable-dark-mode: false;
$enable-negative-margins: true;
$border-width: 2px;

// *Typography
$font-family-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", "Liberation Sans", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-weight: 700;

// *Colors
$cyan: #5aafff;
$yellow: #f6ab35;
$dark: #4d4d4d;

// *Theme colors
$body-bg: #f3f4f6;
$body-color: #333;
$primary: $cyan;
$warning: $yellow;

// *Links
$link-decoration: null;

// *Buttons
$btn-color: #fff;
$btn-font-weight: 700;

// *Navbar
$navbar-nav-link-padding-x: 1rem;
$nav-link-padding-y: 1rem;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($body-color, 30%);
$navbar-light-brand-color: $body-color;
$navbar-light-brand-hover-color: $body-color;

// Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// Some default variable overrides here

// Any default map overrides here

// Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";


//// My Custom code
// Global
html {
    scroll-padding: 5rem;
}

a {
    transition: $transition-base;
}

// Captcha
.grecaptcha-badge {
    visibility: hidden;
}

.grecaptcha-cpr {
    color: rgba($secondary, 90%);
    font-size: .6rem;

    a {
        color: rgba($secondary, 90%);
    }
}

// Custom
.bg-overlay-gradient::after {
    background: linear-gradient(to bottom, rgba($warning, 20%), rgba($primary, 20%)), linear-gradient(to bottom, rgba($body-bg, 90%), rgba($body-bg, 90%));
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

// Lazy images
.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity .2s;
}

// Buttons
.btn-outline-warning {
    --#{$prefix}btn-color: #{$body-color};
    --#{$prefix}btn-hover-color: #{$body-color};
}

// Navbar
.navbar {
    transition: $transition-base;

    .navbar-brand {
        font-weight: 700;
    }

    .nav-link {
        font-weight: 700;

        &.active {
            border-bottom: 2px solid;
        }
    }

    .nav-link-account {
        --bs-navbar-active-color: $body-color;
        --bs-navbar-nav-link-padding-x: .5rem;
        --bs-nav-link-padding-y: .5rem;
        --bs-nav-link-hover-color: $body-color;

        background: $yellow;
    }

    &:has(.navbar-collapse.collapsing),
    &:has(.navbar-collapse.collapse.show) {
        background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    }
}

// Section Hero
.section-hero {
    .section-hero-bg {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    h1 {
        font-size: $h1-font-size * 1.2;
        text-align: center;

        span {
            display: block;
            font-size: $h1-font-size * .65;
            font-weight: 400;
            color: #999;
        }

        @include media-breakpoint-down(lg) {
            font-size: $h1-font-size;

            span {
                font-size: $h1-font-size * .5;
            }
        }

        @include media-breakpoint-up(xxl) {
            font-size: $h1-font-size * 1.5;

            span {
                font-size: $h1-font-size * .8;
            }
        }
    }

    .section-hero-card {
        background: rgba(#F3F5F8, 50%);
        border-radius: .8rem;
    }

    &.bg-overlay-gradient::after {
        height: 100vh;
    }
}

// Page Home
.section-promo-bg {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.section-home-services,
.section-home-bottom {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section-home-advn {
    p {
        margin: 0;
        padding: 0;
    }
}

// Footer
footer {
    a {
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus,
        &:visited {
            color: rgb(255 255 255 / 70%);
        }
    }

    .footer-nav {
        --bs-nav-link-color: #fff;
        --bs-nav-link-font-size: .85rem;
        --bs-nav-link-hover-color: rgb(255 255 255 / 70%);
        --bs-nav-link-padding-x: .5rem;
        --bs-nav-link-padding-y: .5rem;
    }

    .footer-social {
        a {
            &:hover,
            &:focus,
            &:visited {
                opacity: .7;
            }
        }
    }
}
